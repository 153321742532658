import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/asynchronous-javascript-asyncawait",
  "date": "2017-06-13",
  "title": "ASYNCHRONOUS JAVASCRIPT WITH ASYNC/AWAIT",
  "author": "admin",
  "tags": ["development", "javascript", "react"],
  "featuredImage": "feature.jpg",
  "excerpt": "Promises are great but the its nested syntax can get easily complex and hard to follow. With ES2017 async and await keywords for writing asynchronous code makes it more readable and easier to follow than equivalent code based on long promise chains or deeply nested callbacks."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`What’s Async/Await?`}</h2>
    <p>{`The purpose of async/await functions is to simplify the behaviour of using promises synchronously and to perform some behaviour on a group of Promises. Just like Promises are similar to structured callbacks, async/await is similar to combining generators and promises.`}</p>
    <Message type='info' title='Promises' content='However, the entire foundation for `async/await` is promises. In fact every async function you write will return a promise, and every single thing you await will ordinarily be a promise.' mdxType="Message" />
    <p>{`Before diving deep into async/await, let’s identify what a Promise is.`}</p>
    <h2>{`What is a Promise?`}</h2>
    <p>{`The Promise object represents the eventual completion (or failure) of an asynchronous operation, and its resulting value. It is a special kind of javascript object which contains another object.`}</p>
    <p>{`A Promise is in one of these states:`}</p>
    <ul>
      <li parentName="ul">{`Pending – initial state, not fulfilled or rejected`}</li>
      <li parentName="ul">{`Fulfilled – meaning that the operation completed successfully`}</li>
      <li parentName="ul">{`Rejected – meaning that the operation failed`}</li>
    </ul>
    <p>{`To access data in a Promise, you can use .then() as below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function getFirstPost() {
  return getPosts().then((posts) => posts[0]);
}
`}</code></pre>
    <p>{`To capture any errors, you can add `}<inlineCode parentName="p">{`catch()`}</inlineCode>{` after the `}<inlineCode parentName="p">{`then()`}</inlineCode>{` call.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function getFirstPost() {
  return getPosts().then((posts) => posts[0]).catch(error => error);
}
`}</code></pre>
    <p>{`By using async/await we can ditch the callback function. Let’s write the first example in ES6 aync/await.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`async function getFirstPost() {
  const posts = await getPosts();
  return posts[0];
}
`}</code></pre>
    <p>{`Here `}<inlineCode parentName="p">{`await`}</inlineCode>{` will simply pause the execution of the method until the value from the promise is available.`}</p>
    <p>{`Now let’s look how we can catch errors as in the second example above.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`async function getFirstPost() {
  try {
    const posts = await getPosts();
    return posts[0];
  } catch (error) {
    return error;
  }
}
`}</code></pre>
    <p>{`Much simpler isn’t it? One thing you must remember to do is not forgetting the await keyword. Otherwise you will get a promise instead of a value.`}</p>
    <h2>{`Promise chain`}</h2>
    <p>{`You can use `}<inlineCode parentName="p">{`then()`}</inlineCode>{` for asynchronous functions to be seamlessly called within a promise chain.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`getFirstPost().then((post) => console.log(post.title, post.content));
`}</code></pre>
    <h2>{`Awaiting multiple values`}</h2>
    <p>{`If you are calling multiple async functions, for example the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const post = await getFirstPost();
const user = await getFirstUser();
`}</code></pre>
    <p>{`Here, you can only await on one at a time. So you will get the results sequentially, not at the same time.`}</p>
    <p>{`To make it work we have to do something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const [foo, bar] = await Promise.all([getFirstPost(), getFirstUser()]);
`}</code></pre>
    <p>{`Is `}<inlineCode parentName="p">{`await`}</inlineCode>{` can be only used with promises? Not all, you can you await with any `}<inlineCode parentName="p">{`Thenable`}</inlineCode>{`.`}</p>
    <p>{`So what does `}<inlineCode parentName="p">{`Promise.all`}</inlineCode>{` mean? Let’s clarify that first.`}</p>
    <Message type="info" title="Promise.all" content="However, the entire foundation for `async/await` is promises. In fact every async function you write will return a promise, and every single thing you await will ordinarily be a promise." mdxType="Message" />
    <p>{`So can you convert a callback function to async? let’s discuss this bit further.`}</p>
    <h2>{`Converting any function into an Async`}</h2>
    <p>{`Any function can be made asynchronous, including function expressions, arrow functions, and methods.`}</p>
    <p>{`if you understand how promises work, you can get around this by treating the result of an async function as a promise, which itself accepts callbacks.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function getFirstPost(callback) {
  return getPosts().then(function(post) {
    return callback(undefined, post.name);
  }).catch(function(err) {
    return callback(err);
  });
}
`}</code></pre>
    <p>{`It’s important to handle errors properly when it comes to promises. You need to catch errors properly handle them or the error can be lost on the way.`}</p>
    <h2>{`Async iterators with for-await-of`}</h2>
    <p>{`There is an asynchronous iteration scheme, built on top of a new `}<inlineCode parentName="p">{`for-await-of`}</inlineCode>{` loop and async generator functions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Symbol.asyncIterator = Symbol.asyncIterator || Symbol('asyncIterator');
 
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
 
async function* generator() {
  await delay(1000);
  yield 1;
  await delay(1000);
  yield 2;
  await delay(1000);
  yield 3;
}
 
for await (const value of generator()) {
  console.log(value);
}
`}</code></pre>
    <p>{`Understanding promises is the key to asynchronous JavaScript programming. This article hopefully will help you in understanding how async/await works.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      